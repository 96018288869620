define("discourse/plugins/waze-loader/waze/waze-loader", [], function () {
  "use strict";

  (() => {
    "use strict";

    document.addEventListener("discourse-ready", function () {
      var e = document.getElementById("waze-loader");
      e && e.remove();
    }, {
      once: !0
    });
  })();
});